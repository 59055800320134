import { MODAL_TYPES } from '~~/config/constant'
import { useAppStore } from '~/store/app'
import useModal from '~/composables/useModal'

export const useHeader = () => {
  const { $pinia } = useNuxtApp()
  const { $state } = useAppStore($pinia)
  const { openModalWithNavigate } = useModal()

  const currentUser = computed(() => {
    return $state.currentUser
  })

  const userAmount = computed(() => {
    const amount = currentUser?.value?.balance
    return amount || 0
  })

  const avatarImage = computed(() => {
    const avatar = currentUser?.value?.avatar
    return avatar && avatar !== 'avatar-NaN'
      ? `/assets/images/components/desktop/avatar/${avatar}.webp`
      : '/assets/images/components/desktop/avatar/avatar-NaN.webp'
  })

  const onClickAuthor = (modalName = MODAL_TYPES.LOGIN, url = 'login') => {
    openModalWithNavigate(modalName, url)
  }

  return {
    onClickAuthor,
    MODAL_TYPES,
    currentUser,
    userAmount,
    avatarImage
  }
}
